@media print {
    .pagination {
      display: none;
    }
  }
  
  table {
    width: 150%;
    table-layout: fixed;
  }

  .table-outer-container {
    width: 100%; /* Set the desired width for the outer container */
    overflow-x: auto; /* Enable horizontal scrolling */
  }
  
  .table-container {
    display: inline-block; /* Ensure the container doesn't expand to full width */
  }
  
  .custom-table {
    width: 100%; /* Set the desired width for the table */
  }
  
  .table-header-cell {
    border: 2px solid #ccc;
    padding: 5px;
    /* Add any other styles for the header cells */
  }
  
  /* Add other styles as needed */
  
  