.Container {
    display: none;
  }
  
  @media (max-width: 600px) {
    .Container {
      display: block;
    }
  }
  
  .WebContainer {
    display: none;
  }
  
  @media (min-width: 600px) {
    .WebContainer {
      display: block;
    }
  }