.Container {
  display: none;
}

@media (max-width: 600px) {
  .Container {
    display: block;
  }
}

.WebContainer {
  display: none;
}

@media (min-width: 600px) {
  .WebContainer {
    display: block;
  }
}

.custom-row {
  display: flex;
  align-items: flex-start;
}

.scrollable-menu {
  max-height: 200px; /* Adjust the value to set the maximum height */

}

.card-center-content {
  display: flex;
  justify-content: center;  /* Horizontally centers the content */
  align-items: center;      /* Vertically centers the content */
  height: 100%;             /* Takes full height of the parent */
}

