.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Adjust the z-index as needed */
    transition: opacity 0.3s;
  }
  
  .loader-container.hidden {
    opacity: 0;
    pointer-events: none;
  }
  
  
  
  .loader-content {
    margin-top: 10px;
  }
  