/* Replace this with the path to your CSS file if not already linked */
/* Assuming you have a CSS file linked in your project */
/* Example: <link rel="stylesheet" type="text/css" href="path/to/your/styles.css"> */

.outline-input {
     /* border: 2px solid #ccc;  */
     /* padding: 10px;  */
    border-radius: 4px;
    width: 100%;
    outline: none;
    transition: border-color 0.2s;
  }
  
  .outline-input:focus {
    border-color: #2e114a;
    border: 0.5px solid #ccc; /* Make the border thinner */

  }
  