/* YourComponent.css */
.print-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.print-table th,
.print-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.data-table-top {
  /* Add styles for your existing top bar content */
}


