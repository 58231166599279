/* ResponsiveForm.css */
.responsive-form {
    width: 100%;
    max-width: 2000px;
    margin: 0 auto;
  }
  
  /* Hide labels on desktop view */
  .label-desktop {
    display: none;
  }
  
  /* Media query for mobile view */
  @media screen and (max-width: 1500px){
    .label-desktop {
      display: block;
      margin-bottom: 5px;
    }
  
    /* Additional mobile styles can be added here */
  }
  