.chartname {
    margin: 0;          
    padding: 0;         
    position: absolute; 
    top: 0;             
    left: 0;            
}
.Card.Body {
    position: relative;
}
/* styles.css */
.chartname {
    color: #fff;
    border-radius: 5px;
    padding: 5px 20px;
    background: linear-gradient(45deg, #17a2b8, #128293);
    width: 30%; 
}
.route {
    margin: 0;          
    padding: 0;         
    position: absolute; 
    top: 0;             
    left: 3; 
    color: #fff;
    border-radius: 5px;
    padding: 5px 20px;
    background: linear-gradient(45deg, #17a2b8, #128293);
    width: 30%; 
}
.dateContainer {
    position: absolute;
    top: 0;
    right: 80px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    text-align: center;
    width: 17%; 
}

.sessionContainer {
    position: absolute;
    top: 0;
    right: 0px;  /* Increased from 110px to 140px to introduce 30px space */
    padding: 5px 10px;
    background-color: #ffc107;
    color: black;
    border-radius: 5px;
    text-align: center;
    width: 6%; 
}

.iconMarginRight {
    margin-right: 5px;
}

.input-with-hrs {
    position: relative;
}

.input-with-hrs::after {
    content: 'hrs';
    position: absolute;
    right: 10px;   /* adjust as per your need */
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* make sure the hrs text does not interfere with input functionality */
    opacity: 0.6; /* adjust opacity as needed for a subtle look */
}
.flex-container {
    display: flex;
    align-items: center; /* Vertically center the items */
}

.flex-input {
    flex-grow: 1; /* Allow the input to take up the majority of space */
}

.flex-label {
    padding-left: 5px; /* Space between input and label */
}
.custom-card {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.card-body-custom {
    position: relative;
    padding: 20px;
}

.custom-button {
    margin-top: 32px;
    transition: all 0.3s;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    font-size: 16px;
    background: linear-gradient(135deg, #3498db, #2ecc71);
    border: none;
    padding: 10px 25px;
}
.scrollable-list {
    max-height: 165px; /* assuming each item is about 50px in height */
    overflow-y: auto;
}
.highlighted-item {
    background-color:#5130d4 !important;
    color: white !important; /* Adjust text color if necessary */
}
.bottom-right {
    position: relative; 
}

.bottom-right .form-control-wrap {
    position: absolute;
    bottom: 0;
    right: 13px;
}
.count{
    font-weight: bold;
   
}
.quantity{
    font-weight: bold;
}
.milkType{
    padding: 5px 10px;
    background-color : #5f38f9;
    color: white;
    border-radius: 5px;
    text-align: center;
}
.removeButton{
    position: absolute;
    top: 12px;
    right: -6px;
    width: 20px;  
    height: 20px; 
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center ;
}
.triangle-up {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid green;
    display: inline-block;
}

.triangle-down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid red;
    display: inline-block;
}
.dropdown-container {
    position: relative; /* Ensure it's relative so the absolute child is positioned according to this container */
}

.dropdown-results {
    position: absolute; /* Make it absolute so it doesn't push content around */
    top: 100%; /* Start just below the input */
    left: 0;
    right: 0; /* Stretch it to the width of the parent */
    max-height: 200px; /* You can adjust this */
    overflow-y: auto; /* Add scroll if the content is too long */
    z-index: 1000; /* Ensure it's on top of other content */
    width: 100%; /* Optional, but makes it take full width of parent */
}


@media print {
    .print-container {
      display: none;
    }
  }
  
  /* Add this to your CSS file */
.action-buttons.highlighted {
    background-color: #c8e6c9; /* Change this to the desired highlight color */
  }
  .highlighted-row {
    background-color: #bb2020; /* Change this to the desired highlight color */
}
  